import { AppRoutes } from "./pages/routes"

function App() {
	return (
		<>
			<AppRoutes/>
		</>
	)
}

export default App